import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { UpdateSettingsMutation } from "arena/mutations";
import { useMutation } from "@apollo/client";
import { useViewer } from "core/components/ViewerProvider";

export default function SwitchLanguage() {
  const { i18n } = useTranslation("arena");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const viewer = useViewer();
  const [updateSettings] = useMutation(UpdateSettingsMutation);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (language) => {
    i18n.changeLanguage(language);
    setAnchorEl(null);

    if (!viewer) return;

    updateSettings({
      variables: {
        input: {
          language,
        },
      },
    });
  };

  // здесь грузить с сервера не стал, тк перевеоды все равно захардкожены в i18n.js
  const options = [
    { value: "en", label: "English" },
    { value: "ru", label: "Русский" },
    { value: "pl", label: "Polski" },
    { value: "de", label: "Deutsch" },
    { value: "lv", label: "Latviešu" },
    { value: "es", label: "Español" },
    { value: "pt", label: "Português" },
    { value: "fr", label: "Français" },
  ];

  const label =
    options.find((o) => o.value === i18n.language)?.label || i18n.language;

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<LanguageIcon />}
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => onChange(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
