import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import CustomField from "arena/components/CustomField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import AvatarField from "arena/components/AvatarField";
import TimeZoneField from "arena/components/TimeZoneField";
import CountryField from "arena/components/CountryField";
import LanguageField from "arena/components/LanguageField";
import {
  getTimeZone,
  setTimeZone as setGlobalTimeZone,
} from "core/utils/moment";

function CreateProfile({ viewer, onUpdateSettings, loading, onError, arena }) {
  const { t, i18n } = useTranslation("arena");
  const [values, setValues] = useState({
    firstName: viewer.firstName,
    lastName: viewer.lastName,
    country: viewer.country,
    language: viewer.language,
    timeZone: viewer.timeZone || getTimeZone(),
    customFields: {},
  });

  const onSubmit = (event) => {
    event.preventDefault();
    setGlobalTimeZone(values.timeZone);
    onUpdateSettings(values);
  };

  return (
    <form onSubmit={onSubmit}>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {t("onboardingDialog.profile.title")}
          </Typography>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {t("save")}
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} container justifyContent="center">
            <AvatarField
              image={viewer?.photo?.url}
              onError={onError}
              onChange={(photoId) =>
                setValues((values) => ({
                  ...values,
                  photoId,
                }))
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t("onboardingDialog.profile.firstName")}
              value={values.firstName || ""}
              onChange={(event) =>
                setValues((values) => ({
                  ...values,
                  firstName: event.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t("onboardingDialog.profile.lastName")}
              value={values.lastName || ""}
              onChange={(event) =>
                setValues((values) => ({
                  ...values,
                  lastName: event.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CountryField
              required
              label={t("onboardingDialog.profile.country")}
              value={values.country}
              onChange={(country) => {
                setValues((values) => ({
                  ...values,
                  country,
                  timeZone: "",
                }));
              }}
            />
          </Grid>
          {arena?.multilingual && (
            <Grid item xs={12} md={6}>
              <LanguageField
                required
                label={t("onboardingDialog.profile.language")}
                value={values.language}
                onChange={(event) => {
                  i18n.changeLanguage(event.target.value);
                  setValues((values) => ({
                    ...values,
                    language: event.target.value,
                  }));
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TimeZoneField
              required
              country={values.country}
              label={t("onboardingDialog.profile.timeZone")}
              value={values.timeZone}
              onChange={(timeZone) => {
                setValues((values) => ({
                  ...values,
                  timeZone,
                }));
              }}
            />
          </Grid>

          {arena?.userFields?.map((userField, index) => (
            <Grid item xs={12} key={index}>
              <CustomField
                {...userField}
                value={values.customFields[userField.name]}
                onChange={(value) => {
                  setValues((values) => ({
                    ...values,
                    customFields: {
                      ...values.customFields,
                      [userField.name]: value,
                    },
                  }));
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </form>
  );
}

CreateProfile.propTypes = {
  loading: PropTypes.bool,
  onUpdateSettings: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  arena: PropTypes.shape({
    multilingual: PropTypes.bool,
    userFields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
  }),
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    language: PropTypes.string,
    country: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isOnboarded: PropTypes.bool,
    login: PropTypes.string,
    email: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
    timeZone: PropTypes.string,
  }),
};

export default CreateProfile;
