import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { ArenaBannerQuery } from "arena/queries";
import ButtonBase from "@mui/material/ButtonBase";
import ReactGA from "react-ga4";
import { getBannerSrcSet } from "core/utils/getSrcSet";

export default function ArenaBanner({
  bannerId,
  link,
  paddingTop,
  paddingBottom,
  maxWidth,
}) {
  const { data } = useQuery(ArenaBannerQuery, {
    skip: !bannerId,
    variables: {
      id: bannerId,
    },
  });

  const banner = data?.banner;

  if (!banner) return null;

  const url = banner[0]?.url;
  const srcSet = getBannerSrcSet(banner);

  let html = (
    <img
      src={url}
      srcSet={srcSet}
      style={{ width: "100%", maxWidth: "100%", display: "block", margin: 0 }}
    />
  );

  if (link) {
    const onClick = () => {
      ReactGA.event({
        category: "ArenaBanner",
        action: "Click to banner",
        label: link,
      });
    };

    html = (
      <ButtonBase
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: "100%" }}
        onClick={onClick}
      >
        {html}
      </ButtonBase>
    );
  }

  return (
    <div style={{ paddingTop, paddingBottom, maxWidth, margin: "auto" }}>
      {html}
    </div>
  );
}

ArenaBanner.propTypes = {
  bannerId: PropTypes.string.isRequired,
  link: PropTypes.string,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  maxWidth: PropTypes.string,
};
