import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { truncate } from "core/utils/string";
import Grid from "@mui/material/Grid";
import moment from "core/utils/moment";
import PaymentButtons from "core/components/PaymentButtons";

const PREFIX = "PaymentScreen";

const classes = {
  title: `${PREFIX}-title`,
  root: `${PREFIX}-root`,
};

const StyledPlayerPreview = styled(PlayerPreview)(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontSize: "1rem",
    lineHeight: "1",
    // fontWeight: 600,
    fontWeight: 800,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.2rem",
      lineHeight: "1.1",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
  },

  [`& .${classes.root}`]: {
    position: "relative",
    zIndex: 2,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 800,
    margin: "auto",
    padding: theme.spacing(0, 2),
  },
}));

function getSubtitle(t, content) {
  if (content.type === "VIDEO") {
    if (content.accessStartAt) {
      if (moment(content.accessStartAt).isAfter()) {
        return t("contentIsNotAvailable.accessStartAt.text", {
          date: content.accessStartAt,
        });
      }
    }
  }
  if (content.type === "STREAM") {
    switch (content.streamData.status) {
      case "STARTED":
        return t("streamPending.started");

      case "PENDING":
        if (content.startAt) {
          return t("streamPending.textWithDate", { date: content.startAt });
        }

        return null;

      case "FINISHED":
        return t("streamPending.finished", {
          date: content.streamData.finishAt,
        });

      default:
        return null;
    }
  }
}

export default function PaymentScreen({ content, onClickProductButton }) {
  const { t } = useTranslation("core");
  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const products = (content?.products?.nodes || []).filter(
    (product) => product.prices.length > 0 || product.donates.length > 0
  );

  if (products.length === 0) {
    return (
      <StyledPlayerPreview preview={content.preview} overlay>
        <ErrorScreen
          title={t("paymentScreen.productError.title")}
          text={t("paymentScreen.productError.text")}
        />
      </StyledPlayerPreview>
    );
  }

  const title = smUp ? content.title : truncate(content.title, 100);
  const subTitle = getSubtitle(t, content);

  return (
    <StyledPlayerPreview preview={content.preview} overlay>
      <div className={classes.root}>
        <Grid container spacing={smUp ? 3 : 2} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              varianth="h1"
              color="textPrimary"
              align="center"
              className={classes.title}
            >
              {title}
            </Typography>

            {subTitle && (
              <Typography
                varianth="body1"
                color="textSecondary"
                align="center"
                sx={{ mt: 1 }}
              >
                {subTitle}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <PaymentButtons
              products={products}
              onClickProductButton={onClickProductButton}
            />
          </Grid>
        </Grid>
      </div>
    </StyledPlayerPreview>
  );
}

PaymentScreen.propTypes = {
  onClickProductButton: PropTypes.func,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    preview: PropTypes.string,
    startAt: PropTypes.string,
    premium: PropTypes.bool,
    shareUrl: PropTypes.string.isRequired,
    products: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          shareUrl: PropTypes.string.isRequired,
          prices: PropTypes.array.isRequired,
        })
      ),
    }),
  }).isRequired,
};
