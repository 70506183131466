import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import { getAvatarSrcSet } from "core/utils/getSrcSet";

const PREFIX = "TagList";

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  chip: `${PREFIX}-chip`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    overflow: "hidden",
  },
  [`& .${classes.label}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.chip}`]: {
    textOverflow: "ellipsis",
    maxWidth: 260,
  },
  [`& .${classes.list}`]: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflowX: "auto",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
  },
  [`& .${classes.listItem}`]: {
    flexGrow: "0",
    flexShrink: "0",
    width: "auto",
    paddingLeft: 0,
    paddingRight: 0,
  },
});

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name
      .split(" ")
      .map((w) => w[0])
      .slice(0, 2)
      .join(""),
  };
}

export default function TagList({ tags }) {
  const nodes = tags;

  const commonTags = nodes.filter((tag) => !tag.type?.featured);
  const featuredTags = nodes.filter((tag) => tag.type?.featured);

  return (
    <Root className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <List className={classes.list}>
            {featuredTags.map((tag) => {
              return (
                <ListItem className={classes.listItem} key={tag.id}>
                  <ListItemButton component={Link} to={tag.url}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{ width: 48, height: 48 }}
                        src={tag.image?.url}
                        srcSet={getAvatarSrcSet(tag?.image?.thumbnails)}
                        {...stringAvatar(tag.title)}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={tag.title}
                      secondary={tag.type?.title}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>

        {commonTags.map((tag) => (
          <Grid item key={tag.id}>
            <Chip
              color="primary"
              label={tag.title}
              clickable
              component={Link}
              to={tag.url}
              classes={{
                root: classes.chip,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Root>
  );
}

TagList.propTypes = {
  link: PropTypes.func,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    })
  ),
};
