import React from "react";
import PropTypes from "prop-types";
import Grid from "./Grid";
import Carousel from "./Carousel";

import HomepageTitle from "arena/components/HomepageTitle";
import { useQuery } from "@apollo/client";
import { ArenaPreviewsQuery } from "arena/queries";

const components = {
  CAROUSEL: Carousel,
  GRID: Grid,
};

const indexBy = (items) =>
  items.reduce((all, item) => {
    all[item.id] = item;
    return all;
  }, {});

/**
 * Список в стиле полки контента
 */
export default function ArenaItemsList({ title, view, itemsPerRow, items }) {
  const { data } = useQuery(ArenaPreviewsQuery, {
    variables: {
      ids: items.map((item) => item.previewId),
    },
  });

  if (!data?.previews?.length) return null;

  const previewsById = indexBy(data.previews);

  const listItems = items.map((item, index) => {
    return {
      id: index,
      title: item.title,
      url: item.url,
      thumbnails: previewsById[item.previewId]?.thumbnails,
      openInNewWindow: item.openInNewWindow,
    };
  });

  const Component = components[view];

  const sm = Math.max(12 / itemsPerRow, 4);
  const md = Math.max(12 / itemsPerRow, 3);
  const lg = 12 / itemsPerRow;
  const xl = lg;

  return (
    <>
      {title && <HomepageTitle title={title} />}

      <Component items={listItems} xs={12} sm={sm} md={md} lg={lg} xl={xl} />
    </>
  );
}

ArenaItemsList.defaultProps = {
  itemsPerRow: 3,
};

ArenaItemsList.propTypes = {
  title: PropTypes.string,
  view: PropTypes.string,
  itemsPerRow: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      previewId: PropTypes.string.isRequired,
    })
  ),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
