import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import classNames from "classnames";
import { textFormat } from "core/utils/string";
import TagList from "arena/components/TagList";

const PREFIX = "ContentBody";

const classes = {
  root: `${PREFIX}-root`,
  padding: `${PREFIX}-padding`,
  tagsSection: `${PREFIX}-tagsSection`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
};

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0),
  },

  [`&.${classes.padding}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },

  [`& .${classes.tagsSection}`]: {
    marginTop: theme.spacing(4),
  },

  [`& .${classes.title}`]: {
    fontWeight: 800,
    lineHeight: 1.1,
    fontSize: "2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  },

  [`& .${classes.description}`]: {
    marginTop: 32,
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
    "& a": {
      color: "#81d4fa",
    },
    "& p": {
      "&:first-of-type": {
        marginTop: 0,
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
}));

const COLLECTION = "COLLECTION";
const STREAM = "STREAM";
const VIDEO = "VIDEO";

export default function ContentBody({ content, loading, padding }) {
  if (loading) {
    return (
      <Root>
        <Skeleton
          variant="rectangular"
          sx={{ width: "100%", height: 36, marginBottom: 4 }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: "90%", height: 24, marginBottom: 1 }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: "80%", height: 24, marginBottom: 1 }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: "87%", height: 24, marginBottom: 1 }}
        />
      </Root>
    );
  }

  if (!content) return null;

  const description = content?.description;
  const premiumDescription = content?.premiumDescription;

  let title = true;

  if (content.premium) {
    if (content.type !== COLLECTION) {
      if (!content.viewerPurchase) {
        title = false; // если это премиум контент, а покупки нет, не показывать
      }
    }
  }

  return (
    <Root
      className={classNames({
        [classes.padding]: padding,
      })}
    >
      {title && (
        <Typography
          variant="body1"
          color="textPrimary"
          component="h1"
          align="left"
          display="inline"
          className={classes.title}
        >
          {content.title}
        </Typography>
      )}

      {description && (
        <Typography
          className={classes.description}
          component="div"
          variant="body2"
          color="textSecondary"
          dangerouslySetInnerHTML={{ __html: textFormat(description) }}
        />
      )}

      {premiumDescription && (
        <Typography
          className={classes.description}
          component="div"
          variant="body2"
          color="textSecondary"
          dangerouslySetInnerHTML={{ __html: textFormat(premiumDescription) }}
        />
      )}

      {content.tags.length > 0 && (
        <div className={classes.tagsSection}>
          <TagList tags={content.tags} />
        </div>
      )}
    </Root>
  );
}

ContentBody.propTypes = {
  loading: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    preview: PropTypes.string,
    publishAt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    premiumDescription: PropTypes.string,
    type: PropTypes.oneOf([VIDEO, STREAM, COLLECTION]),
    // totalViews: PropTypes.number,
    // viewersOnline: PropTypes.number,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  }),

  padding: PropTypes.bool,
};
